import baseStyled, { ThemedCssFunction, ThemedStyledInterface, css as baseCss } from 'styled-components';

import { LayoutTheme } from './themes/types';
import Page from '@plasma/ui.layout.page';

const styled = baseStyled as ThemedStyledInterface<LayoutTheme>;
const css = baseCss as ThemedCssFunction<LayoutTheme>;

export const Layout = styled(Page)`
  height: 100%;
  overflow: hidden;
  width: 100%;

  .plasma-panel {
    padding: 10px;
    width: 100%;
    overflow: hidden;
  }
`;
